import { Injectable, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';

export interface User {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  phoneNumber: string;
  emailVerified: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {

  private authState: Observable<firebase.User>;
  public userData: any;
  public user: firebase.User = null;
  private userDoc: AngularFirestoreDocument<any>;
  private _sessionId: string;

  public showSignInLoader: boolean = false;
  public showSignUpLoader: boolean = false;
  public showFacebookLoader: boolean = false;
  public showGoogleLoader: boolean = false;

  constructor(public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    public toster: ToastrService,
    private cookieService: CookieService) {

    this.authState = this.afAuth.authState;

    this.getAuthState().subscribe(user => {
      if (user) {
        this.userData = user;
        this._sessionId = this.userData;
        cookieService.set('user', JSON.stringify(this.userData));
        //JSON.parse(cookieService.get('user'));
        localStorage.setItem('user', JSON.stringify(this.userData));
        //JSON.parse(localStorage.getItem('user'));
        this.user = user;

        console.log('AuthService - Constructor, user authenticated: ', user);


        // Saves user data in local storage. AuthGuard will use it to restric access to
        // internal pages.
        this.getUserData().subscribe( data => {
          console.log('Auth - getUserData: ', data);
          cookieService.set('user-data', JSON.stringify(data));
          localStorage.setItem('user-data', JSON.stringify(data));
        }, err => {
          console.log('ProfilePage - getUserData ERROR: ', err);
          localStorage.setItem('user-data', null);
        });




      } else {
        localStorage.setItem('user', null);
        //JSON.parse(localStorage.getItem('user'));
        this.user = null;
        
        localStorage.setItem('user-data', null);
      }
    });
  }

  ngOnInit(): void { }

  getAuthState() {
    return this.authState;
  }

  // sign up function
  signUp(user:any): Promise<any> {
    return this.afAuth.auth.createUserWithEmailAndPassword(user.email, user.password)
    .then((newUser) => {
      console.log("userid ========= " + newUser.user.uid);   // firebase.database().ref('/userProfile').child(newUser.uid).set({
      
      //console.log('newUser.user ========= ', newUser.user);

      // Creates user in the database and then updates the displayName
      this.setUserData(newUser.user).then(res => {
        this.updateUserData({displayName: user.displayName});
      });
      this.sendVerificationMail('wizard');
      this.showSignUpLoader = true;

      return newUser;  
    });
  }

  // sign in function
  signIn(email, password) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        if (result.user.emailVerified !== true) {
          console.log('AuthService - SignIn, email NOT verified');
          //this.SetUserData(result.user);
          this.sendVerificationMail('dashboard');
          this.showSignInLoader = true;
        } else {
          console.log('AuthService - SignIn, email verified');
          this.showSignInLoader = false;
          this.ngZone.run(() => {
            console.log('AuthService - SignIn Email Verified, NgZone');
            //this.router.navigate(['/auth/login']);
            //this.router.navigate(['/dashboard/default']);
            this.router.navigate(['/dashboard']);
          });
        }
      }).catch((error) => {
        this.toster.error('You have enter Wrong Email or Password.');
      });
  }

  // Checks whether user is present in database
  isUserInDB(id: string): Promise<boolean> {
    const db = firebase.firestore();
    //if (this.user) {
      return db.collection('rest_users').doc(id).get()
      .then(doc => {
        console.log('AuthService isUserInDB - doc: ', doc)
        return doc.exists;
      });
    //} else {
    //  return Promise.resolve(false);
    //}
  }

  // Creates new user in database if not present
  async createsNewUserAtSignIn(newUser) {

    return this.isUserInDB(newUser.user.uid)
      .then(isUser => {

        if(isUser) {
          console.log('AuthService - user already exists in DB: ', isUser);
          //this.router.navigateByUrl('/dashboard');
          //this.router.navigate(['/dashboard']);
          this.ngZone.run(() => {
            console.log('createsNewUserAtSignIn - NgZone - heading to dashboard');
            this.router.navigate(['/dashboard']);
          });
        }
        else {
          console.log('AuthService - user NOT in DB: ', isUser);
          this.setUserData(newUser.user);
          //this.router.navigateByUrl('/wizard');
          this.ngZone.run(() => {
            console.log('createsNewUserAtSignIn - NgZone - heading to wizard');
            this.router.navigate(['/wizard']);
          });
        }

        return newUser;
      });
  }

  //main verification function
  sendVerificationMail(route: string) {
    return this.afAuth.auth.currentUser.sendEmailVerification()
      .then(() => {
        //this.router.navigateByUrl('/dashboard/default');
        this.router.navigateByUrl('/'+route);
      })
  }

  //Sign in with Facebook
  signInFacebok() {
    return this.AuthLogin(new auth.FacebookAuthProvider());
  }

  //Sign in with Twitter
  signInTwitter() {
    return this.AuthLogin(new auth.TwitterAuthProvider());
  }

  //Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }

  ForgotPassword(passwordResetEmail) {
    return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      }).catch((error) => {
        window.alert(error);
      });
  }

  //Authentication for Login
  AuthLogin(provider) {
    return this.afAuth.auth.signInWithPopup(provider)
      .then((newUser) => {
        /*this.ngZone.run(() => {
          this.router.navigate(['/dashboard']);
        });*/
        //this.setUserData(result.user);
        return this.createsNewUserAtSignIn(newUser)
        .then( newUser => {
          return newUser;
        });

      }).catch((error) => {
        window.alert(error);
      });
  }

  //Authentication for Login
  /*AuthLogin(provider) {
    return this.afAuth.auth.signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['/dashboard/default']);
        });
        this.SetUserData(result.user);
      }).catch((error) => {
        window.alert(error);
      });
  }

  //Set user
  SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      email: user.email,
      displayName: user.displayName,
      uid: user.uid,
      photoURL: user.photoURL || 'assets/dashboeard/boy-2.png',
      emailVerified: user.emailVerified
    };
    userRef.delete().then(function () {})
          .catch(function (error) {});
    return userRef.set(userData, {
      merge: true
    });
  }*/

  // Sign out
  SignOut() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    return this.afAuth.auth.signOut().then(() => {

      this.showSignInLoader = false;
      this.showSignUpLoader = false;
      this.showFacebookLoader = false;
      this.showGoogleLoader = false;

      localStorage.clear();
      this.cookieService.deleteAll('user', '/auth/login');
      this.router.navigate(['/auth/login']);
    });
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user != null && user.emailVerified != false) ? true : false;
  }

/*************************
  *  User related methods
  *************************/

  // Gets user data from user object obtained at signIn

  //Set user
  setUserData(user) {
    //const userRef: AngularFirestoreDocument<any> = this.afs.doc(`rest-users/${user.uid}`);
    const userData: User = {
      email: user.email,
      displayName: user.displayName ? user.displayName:'',
      uid: user.uid,
      photoURL: user.photoURL || 'assets/dashboeard/boy-2.png',
      emailVerified: user.emailVerified,
      phoneNumber: user.phoneNumber ? user.phoneNumber:''
    };
    /*userRef.delete().then(function () {
    }).catch(function (error) {
    });
    return userRef.set(userData, {
      merge: true
    });*/

    return this.afs.collection('rest_users').doc(user.uid).set(userData);
  }


  getUserData(): Observable<any> {
    if (this.user) {
      this.userDoc = this.afs.doc<any>('rest_users/' + this.user.uid);
      return this.userDoc.valueChanges()
      .pipe(map(action => {
          console.log('AuthService - getUserData: ', action);
          return action;
        }));
    } else {
      //return Observable.throw(new Error('No User Logged In!'));
      return throwError(new Error('No User Logged In!'));
    }
  }



  getUserDataOnce(): Promise<any> {
    if (this.user) {
    //Testing one read to Firestore only
      return this.afs.firestore.doc('rest_users/' + this.user.uid).get()
        .then(docSnapshot => {
          if (docSnapshot.exists) {
            console.log('UserService - testFirestore, doc does exist');
            console.log('UserService - testFirestore: ', docSnapshot.data());
            return docSnapshot.data();
          }
          else {
            console.log('UserService - testFirestore, doc does NOT exist');
            return null;
          }
        });
    } else {
      return Promise.reject(new Error('No User Logged In!'));
    }
  }


  updateUserData(data) {
    if (this.user) {
      this.afs.collection('rest_users').doc(this.user.uid).update(data);
    } else {
      return Promise.reject(new Error('No User Logged In!'));
    }
  }




  /********************************
  *      Culqi methods
  ********************************/

  getPaymentKeys(type: string): Observable<any> {
    return this.afs.collection<any>('payment_platforms', ref => ref.where('name', '==', 'Culqi').where('type', '==', type)).valueChanges();
  }


  getCulqiCustomer(): Observable<any> {
    if (this.user) {
      this.userDoc = this.afs.doc<any>('culqi/' + this.user.uid);
      return this.userDoc.valueChanges()
      .pipe(map(action => {
          const data = action;
          console.log('UserService - getCulqiCustomer: ', data);
          return data;
        }));
    } else {
      //return Observable.throw(new Error('No User Logged In!'));
      return throwError(new Error('auth/user-not-logged-in'));
    }
  }

  setCulqiCustomer(data) {
    if (this.user) {
      return this.afs.collection('culqi').doc(this.user.uid).set(data);
    } else {
      return Promise.reject(new Error('auth/user-not-logged-in'));
    }
  }

  // Deletes CulqiCustomer from Firebase only
  deleteCulqiCustomer() {
    if (this.user) {
      return this.afs.collection('culqi').doc(this.user.uid).delete();
    } else {
      return Promise.reject(new Error('auth/user-not-logged-in'));
    }
  }

  /*updateCulqiCustomer(customer_id: string): Promise<any> {
    return this.afs.collection('culqi').doc(this.currentUser.uid).update({
      customer_id: customer_id
    });
  }*/

  addCulqiCard(card: any): Promise<any> {
    return this.afs.collection('culqi').doc(this.user.uid).collection('cards').add({
      card_id: card.id,
      card_number: card.card_number
    });
  }

/*
  removeCulqiCard(card_id: string): Promise<void> {
    const db = firebase.firestore();
    if (this.userId) {
      return db.collection('culqi').doc(this.currentUser.uid).collection('cards').where('card_id', '==', card_id).get()
      .then(doc => {
        doc.forEach( docu => {
          return db.doc('culqi/' + docu.id).delete();
        });
      });
    } else {
      return Promise.reject(new Error('No User Logged In!'));
    }
  }
*/
}

