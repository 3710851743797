<div class="page-wrapper">
	<div class="text-center"><img src="assets/images/qpon-logo.png" alt=""></div>

	<div class="text-center">
		<h4>Bienvenido al portal de Qpon</h4>
		<h6>Aquí puedes administrar tu sistema de fidelidad, visualizar estadísticas y crear promociones para tu local. Regístrate ahora, es muy sencillo. </h6>
	</div>
			
	<div class="auth-bg">
		<div class="authentication-box">
			<div class="card mt-4">
				<div class="card-body">
					<!--<div class="text-center">
						<h4>LOGIN</h4>
						<h6>Enter your Username and Password </h6>
					</div>-->
					
					<form class="theme-form" [formGroup]="loginForm" *ngIf="!newUser">

						<div class="form-group form-row mt-3 mb-0">
							<button class="btn btn-fb btn-block btn-lg"
								[class.loader--text]="authService.showFacebookLoader"
								[disabled]="authService.showSignUpLoader || authService.showSignInLoader || authService.showFacebookLoader || authService.showGoogleLoader"
								(click)="loginFacebook()">
								<span>{{ authService.showFacebookLoader ? '' : 'Iniciar sesión con Facebook' }}</span>
							</button>
						</div>
						<div class="form-group form-row mt-3 mb-0">
							<button class="btn btn-google btn-block btn-lg"
								[class.loader--text]="authService.showGoogleLoader"
								[disabled]="authService.showSignUpLoader || authService.showSignInLoader || authService.showFacebookLoader || authService.showGoogleLoader"
								(click)="loginGoogle()">
								<span>{{ authService.showGoogleLoader ? '' : 'Iniciar sesión con Google' }}</span>
							</button>
						</div>

						<div class="login-divider"></div>

						<div class="form-group">
							<label class="col-form-label pt-0">Email</label>
							<input class="form-control" formControlName="email" type="email" required="">
							<div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required"
								class="text text-danger mt-1">
								Se requiere Email
							</div>
							<div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email"
								class="text text-danger mt-1">
								Email inválido
							</div>
						</div>
						<div class="form-group">
							<label class="col-form-label">Contraseña</label>
							<input class="form-control" type="password" formControlName="password" required="">
							<div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
								class="text text-danger mt-1">
								Se requiere contraseña
							</div>
						</div>
						<!--<div class="checkbox p-0">
							<input id="checkbox1" type="checkbox">
							<label for="checkbox1">Remember me</label>
						</div>-->
						<div class="form-group form-row mt-3 mb-0">
							<button class="btn btn-primary btn-block btn-lg"
								[class.loader--text]="authService.showSignInLoader"
								[disabled]="!loginForm.valid || authService.showSignUpLoader || authService.showSignInLoader || authService.showFacebookLoader || authService.showGoogleLoader" (click)="login()"
								type="submit"><span>{{ authService.showSignInLoader ? '' : 'Inicar sesión' }}</span>
							</button>
						</div>
						<!--<div class="login-divider"></div>
						<div class="social mt-3">
							<div class="form-group btn-showcase d-flex">
								<button class="btn social-btn btn-fb d-inline-block" (click)="loginFacebook()">
									<i class="fa fa-facebook"></i>
								</button>
								<button class="btn social-btn btn-twitter d-inline-block" (click)="loginTwitter()">
									<i class="fa fa-twitter"></i>
								</button>
								<button class="btn social-btn btn-google d-inline-block" (click)="loginGoogle()">
									<i class="fa fa-google"></i>
								</button>
							</div>
						</div>-->
					</form>
				</div>
			</div>




		</div>
	<!--</div>-->


	<!--<div class="auth-bg">-->
    <div class="authentication-box">
      <!--<div class="text-center"><img src="assets/images/endless-logo.png" alt=""></div>-->
      

      <div class="card mt-4 p-4">
        <!--<h4 class="text-center">NEW USER</h4>
        <h6 class="text-center">Enter your Username and Password For Signup</h6>-->
        <form class="theme-form" [formGroup]="signupForm">
        	<h5 class="text-center">Regístrate con <span class="active" role="button" aria-pressed="true" (click)="loginFacebook()"><b>Facebook</b></span> o <span class="active" role="button" aria-pressed="true" (click)="loginGoogle()"><b>Google</b></span>
        	</h5>
        	<div class="form-divider"></div>
          <div class="form-row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="col-form-label">Nombre</label>
                <input class="form-control" formControlName="firstname" type="text">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="col-form-label">Apellido</label>
                <input class="form-control" formControlName="lastname" type="text">
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="col-form-label">Email</label>
            <input class="form-control" formControlName="email" type="email">
          </div>

          <div class="form-group">
            <label class="col-form-label">Contraseña</label>
            <input class="form-control" formControlName="password" type="password">
          </div>
          <!--<div class="form-group">
            <label class="col-form-label">BOD</label>
            <div class="form-row">
              <div class="col-sm-4">
                <select class="form-control mb-1">
                  <option>DD</option>
                  <option>01</option>
                  <option>02</option>
                  <option>03</option>
                  <option>04</option>
                </select>
              </div>
              <div class="col-sm-4">
                <select class="form-control mb-1">
                  <option>MM</option>
                  <option>01</option>
                  <option>02</option>
                  <option>03</option>
                  <option>04</option>
                </select>
              </div>
              <div class="col-sm-4">
                <select class="form-control mb-1">
                  <option>YYYY</option>
                  <option>1990</option>
                  <option>1991</option>
                  <option>1992</option>
                  <option>1993</option>
                </select>
              </div>
            </div>
          </div>-->

          <div class="checkbox p-0 form-group">
						<input class="form-control" formControlName="terms" id="checkbox1" type="checkbox" required="">
						<label for="checkbox1">Acepto los términos y condiciones y política de privacidad. </label>
					</div>


					
          <!--<div class="form-row">-->
            <!--<div class="col-sm-4">
              <button class="btn btn-primary" type="submit">Sign Up</button>
            </div>
            <div class="col-sm-8">
              <div class="text-left mt-2 m-l-20">Are you already user?  <a class="btn-link text-capitalize" href="login.html">Login</a></div>
            </div>-->

            <div class="form-group form-row mt-3 mb-0">
							<button class="btn btn-primary btn-block btn-lg"
								[class.loader--text]="authService.showSignUpLoader"
								[disabled]="!signupForm.valid || authService.showSignUpLoader || authService.showSignInLoader || authService.showFacebookLoader || authService.showGoogleLoader" (click)="signup()"
								type="submit"><span>{{ authService.showSignUpLoader ? '' : 'Registrarse' }}</span>
							</button>
						</div>
          <!--</div>-->
          <!--<div class="form-divider"></div>
          <div class="social mt-3">
            <div class="form-group btn-showcase d-flex">
              <button class="btn social-btn btn-fb d-inline-block"> <i class="fa fa-facebook"></i></button>
              <button class="btn social-btn btn-twitter d-inline-block"><i class="fa fa-google"></i></button>
              <button class="btn social-btn btn-google d-inline-block"><i class="fa fa-twitter"></i></button>
              <button class="btn social-btn btn-github d-inline-block"><i class="fa fa-github"></i></button>
            </div>
          </div>-->
        </form>
      </div>



    </div>
  </div>



</div>