import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/firebase/auth.service';


@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  userData = null;

  constructor(public authService: AuthService,
              public router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Guard for user is login or not
    let user = JSON.parse(localStorage.getItem('user'));
    let userData = JSON.parse(localStorage.getItem('user-data'));

    // Checks whether user is logged in
    if (!user) {
      console.log('AdminGuard - No User Logged In... redirecting to auth/login...');
      this.router.navigate(['/auth/login']);
      return false;
    }


    if(userData) {
      console.log('AdminGuard (localStorage) - userData available...');
      // Checks whether paymentMethod is configured (localStorage)
      if( !userData.wizardCompleted ) {
        console.log('AdminGuard (localStorage) - paymentMethod not configured... redirecting to Wizard...');
        this.router.navigate(['/wizard']);
        return false;
      }

    }
    else {
      // Checks whether paymentMethod is configured (Promise)
      this.authService.getUserDataOnce()
      .then(data => {

        if( !data.wizardCompleted ) {
          console.log('AdminGuard (Promise) - wizardCompleted not configured... redirecting to Wizard...');
          this.router.navigate(['/wizard']);
          return false;
        }
        // If NOT in If, continues...

      }).catch(err => {
        console.log('AdminGuard (Promise) - ERROR getting user data: ', err);
        // This was added to avoid refreshing page. It redirects to login page.
        this.router.navigate(['/auth/login']);
        return false;
      });

    } // End of ELSE

    // None of the above, allows to enter in requested page
    console.log('AdminGuard - User Logged In... does nothing...');
    return true;
  }

}
