import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class RestaurantService {

  private user: firebase.User = null;

  constructor(private afs: AngularFirestore, private authService: AuthService) { 

    this.authService.getAuthState()
      .subscribe((user) => {
        if (user) {
          // User is signed in.
          this.user = user;
        } else {
          this.user = null;
        }
      });

  }


  getSingleRestaurant(restId: string) {
    console.log("start getSingleRestaurant");
    return this.afs.doc<any>('restaurants/'+ restId).valueChanges();
  }


  addRestaurant(data) {
    if (this.user) {
      data.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      data.updatedAt = data.createdAt;
      return this.afs.collection('restaurants').add(data);

      console.log('RestaurantService - addNewRestaurant, data: ', data);

    } else {
      return Promise.reject(new Error('No User Logged In!'));
    }
  }


  updateRestaurant(restId, data) {
    if (this.user) {
      data.updatedAt = firebase.firestore.FieldValue.serverTimestamp();      
      return this.afs.collection('restaurants').doc(restId).update(data);
    } else {
      return Promise.reject(new Error('No User Logged In!'));
    }
  }

  updateRestaurantImage(restId, img) {
    let data;
    if (this.user) {
      
      if(img.type=='logo') {
        data = {logo: {
                  id: img.id,
                  url: img.url
                  }
                };
      } else {
        data = {image: {
                  id: img.id,
                  url: img.url
                  }
                };

      }
      
      data.updatedAt = firebase.firestore.FieldValue.serverTimestamp();      
      return this.afs.collection('restaurants').doc(restId).update(data);
    } else {
      return Promise.reject(new Error('No User Logged In!'));
    }
  }



}