// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // PRODUCTION FIREBASE CONFIG
  firebase: {
    apiKey: "AIzaSyAABrvfpgg4-18ErWcipGoWe7tD2e482O0",
    authDomain: "caffeup-e60f6.firebaseapp.com",
    databaseURL: "https://caffeup-e60f6.firebaseio.com",
    projectId: "caffeup-e60f6",
    storageBucket: "caffeup-e60f6.appspot.com",
    messagingSenderId: "663572166784",
    appId: "1:663572166784:web:51d290e38588e0d00b4e65"
  },
  culqiPaymentMode: 'live',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
