import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }

  reportData(q: string) {

    const query = q;

    const postParams = {
      query: query
    };

   return this.http.post<any[]>("https://us-central1-caffeup-e60f6.cloudfunctions.net/getReportData", postParams)
     .pipe(map((result: any) => result));
  }

}



